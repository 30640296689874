// app/javascript/controllers/custom_chart_controller.js
import ChartController from "@stimulus-components/chartjs";

export default class extends ChartController {
  connect() {
    super.connect();
    // Set the tick callback directly in the chart options after initialization
    this.chart.options.scales.y.ticks.callback = this.tickCallback.bind(this);
    this.chart.update();
  }

  tickCallback(value) {
    // Custom tick label handling
    if (value === 1) return "Positive";
    if (value === 0) return "Neutral";
    if (value === -1) return "Negative";
    return ""; // Empty string for other values
  }
}
