// app/javascript/controllers/index.js
import { Application } from "@hotwired/stimulus";
import Notification from '@stimulus-components/notification';
import Popover from '@stimulus-components/popover';
import CustomChartController from "./custom_chart_controller";
import Dialog from '@stimulus-components/dialog';
import ModalController from "./modal_controller"; 
import CharacterCounter from '@stimulus-components/character-counter';
import TimeController from "./time_controller";

const application = Application.start();

application.register('notification', Notification);
application.register('popover', Popover);
application.register("chart", CustomChartController);
application.register('dialog', Dialog);
application.register("modal", ModalController); 
application.register('character-counter', CharacterCounter);
application.register('time', TimeController);