import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="time"
export default class extends Controller {
  connect() {
    const timeElement = this.element;
    const utcTime = timeElement.getAttribute('data-time');
    
    // Convert the UTC time to local time
    const localTime = new Date(utcTime).toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
    
    timeElement.textContent = localTime;
  }
}
