// app/javascript/controllers/modal_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    // Ensure the modal is hidden on connect
    this.hide();
  }

  toggle(event) {
    event.preventDefault();
    this.modalTarget.classList.toggle("hidden");
    this.modalTarget.classList.toggle("flex"); // Use flex for centering
  }

  show() {
    this.modalTarget.classList.remove("hidden");
    this.modalTarget.classList.add("flex"); // Use flex for centering
  }

  hide() {
    this.modalTarget.classList.add("hidden");
    this.modalTarget.classList.remove("flex");
  }
}
